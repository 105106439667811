export * from '@oracle-cx-commerce/react-widgets';

export const CsHomeContainer = () => import('./common/cs-home-container');
export const CsProductsContainer = () => import('./product/cs-products-container');

export const CsLogo = () => import('./common/cs-logo');
export const CsTitle = () => import('./common/cs-title');
export const CsButton = () => import('./common/cs-button');

export const CsProductsCarousel = () => import('./product/cs-products-carousel');
export const CsProductDetailsContainer = () => import('./product/cs-product-details-container');
export const CsProductImages = () => import('./product/cs-product-images');
export const CsProductName = () => import('./product/cs-product-name');
export const CsProductLikes = () => import('./product/cs-product-likes');
export const CsProductQrCode = () => import('./product/cs-product-qrcode');

export const CsSetupContainer = () => import('./setup/cs-setup-container');
export const CsSetupAction = () => import('./setup/cs-setup-action');
export const CsSetupButton = () => import('./setup/cs-setup-button');
